


































import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
  components: { OPartnerList: () => import('@/components/organisms/globalTools/partner/o-partner-list.vue')},

  setup(){
    const state = reactive({
      items: [
        { value: 'list', text: 'Lista' },
        { value: 'grid', text: 'Kafelki' }
      ],
      select: 'list',
      search: ''
    })

    return { state }
  }
})
